import React, {useEffect} from 'react'
import {
  TheContent,
  TheFooter,
  TheHeader
} from './index'
import { Redirect, Route } from 'react-router-dom'
import * as authService from '../utils/services/auth';
import {useKeycloak} from "@react-keycloak/web";

const TheLayout = (...rest) => {
  const {keycloak} = useKeycloak();
  useEffect(() => {
    authService.updateToken(keycloak);
  }, [keycloak.authenticated]);
  return (
    <Route
      {...rest}
      render={props =>
        authService.isLoggedIn() ? (
          <div className="c-app c-default-layout">
            <div className="c-wrapper">
              <TheHeader/>
              <div className="c-body">
                <TheContent public={false}/>
              </div>
              <TheFooter/>
            </div>
          </div>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default TheLayout
