import * as reportConstants from '../../constants/report'

export const findAssessmentIsReporting = (assessments) => {
  return assessments ? assessments.find(assessment => +assessment.assessment_status !== 3) : null;
}

export const getAssessmentsByRole = (report, role) => {
  if(+role === reportConstants.REPORT_OF_COACH) {
    return report.report_coach.assessments;
  }
  return report.report_student.assessments;
}

export const dataNewAssessmentDetails = (reports, newAssessment) => {
  reports.forEach(report => {
    newAssessment.assessment_details.push(
      {outcome_id: report.id, level: report.level, report: getValueReportDefault(report.assessmentDetails)}
    )
  })
  return newAssessment;
}

export const addNewAssessment = (reports, action) => {
  reports.reports.forEach(report => {
    let assessment_detail = action.assessment_details.find(assessment_detail =>
      +assessment_detail.outcome_id === +report.id && +assessment_detail.level === +report.level
    )
    report.assessmentDetails.push({report: assessment_detail.report, report_date: null, report_status: assessment_detail.assessment_status});
  })
  reports.assessments.push(action.assessment);
  return reports;
}

export const getValueReportDefault = (assessmentDetails) => {
  let reportValueDefault = null;
  if (assessmentDetails.length > 0) {
    reportValueDefault = assessmentDetails[assessmentDetails.length - 1].report;
  }
  return reportValueDefault;
}
