export const getCurrentDate = (format) => {
  let newDate = new Date()
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  switch (format) {
    case 'Y-m-d':
      return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`;
    case 'd-m-Y':
      return `${day < 10 ? `0${day}` : `${day}`}-${month < 10 ? `0${month}` : `${month}`}-${year}`;
    default:
      return `${day < 10 ? `0${day}` : `${day}`}-${month < 10 ? `0${month}` : `${month}`}-${year}`;
  }
}

export const getDateTime = (dateTime) => {
  return new Date(dateTime).toLocaleString('en-GB')
}

