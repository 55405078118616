export const ADD_ASSESSMENT = "ADD_ASSESSMENT";
export const ADD_ASSESSMENT_DETAILS = "ADD_ASSESSMENT_DETAILS";

export const REMOVE_ASSESSMENT_LOADING = 'REMOVE_ASSESSMENT_LOADING';
export const REMOVE_ASSESSMENT = 'REMOVE_ASSESSMENT';
export const REMOVE_ASSESSMENT_ERROR = 'REMOVE_ASSESSMENT_ERROR';

export const FETCH_LAST_REPORT_OF_STUDENT = "FETCH_LAST_REPORT_OF_STUDENT";
export const FETCH_LAST_REPORT_OF_COACH = "FETCH_LAST_REPORT_OF_COACH";
export const FETCH_LAST_REPORT_ERROR = "FETCH_LAST_REPORT_ERROR";
