import * as actionSettingType from '../../constants/actionSettingType';
var initialState = {
  disabledAction: false,
};

var settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionSettingType.CHANGE_STATUS_ACTION:
      return {
        ...state,
        disabledAction: action.status
      }
    default:
      return {...state};
  }
}
export default settingReducer;
