export const isLoggedIn = () => {
  return !!(localStorage.getItem('access_token') &&
    JSON.parse(localStorage.getItem('reduxState')).auth.user !== undefined
  );
}

export const updateToken = (keycloak) =>{
  keycloak.onTokenExpired = () => keycloak.updateToken(8640000).then(function(refreshed) {
    if (refreshed) {
      localStorage.setItem('access_token', keycloak.token);
    } else {
      localStorage.clear();
      keycloak.logout();
    }
  }).catch(function() {
    localStorage.clear();
    keycloak.logout();
  });
}
