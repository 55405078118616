export const saveReduxStateToLocalStorage = (state) => {
    try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serialisedState);
    } catch (e) {
    }
};
export const loadFromLocalStorage = () => {
    try {
        const serialisedState = localStorage.getItem('reduxState');
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        return undefined;
    }
};
export const getCompetencesFromLocalStorage = () => {
    let competences = [];
    if (localStorage.getItem('reduxState') && JSON.parse(localStorage.getItem('reduxState')).competences.competences) {
        competences = JSON.parse(localStorage.getItem('reduxState')).competences.competences;
    }
    return competences;
};
export const getCompetenceFromLocalStorageById = (id) => {
    let competence = null;
    let competences = getCompetencesFromLocalStorage();
    if (competences.length > 0) {
        competence = competences.find(competence => competence.id === +id);
    }
    return competence;
};
export const getOutcomesFromLocalStorage = (competenceId) => {
    let outcomes = [];
    if (localStorage.getItem('reduxState')) {
        if (JSON.parse(localStorage.getItem('reduxState')).outcomes.competenceId
            && JSON.parse(localStorage.getItem('reduxState')).outcomes.competenceId === competenceId) {
            outcomes = JSON.parse(localStorage.getItem('reduxState')).outcomes.outcomes;
        }
    }
    return outcomes;
};
export const saveAccessToken = (access_token) => {
    localStorage.setItem('access_token', access_token);
};
export const getAccessToken = () => {
    return localStorage.getItem('access_token');
};
export const clear = () => {
    localStorage.clear();
};
export const getGroupsFromLocalStorage = () => {
    let groups = [];
    if (localStorage.getItem('reduxState')) {
        if (JSON.parse(localStorage.getItem('reduxState')).groups.groups) {
            groups = JSON.parse(localStorage.getItem('reduxState')).groups.groups;
        }
    }
    return groups;
};
export const getGroupFromLocalStorageById = (groupId) => {
    let group = null;
    let groups = getGroupsFromLocalStorage();
    if (groups.length > 0) {
        group = groups.find(group => group.id === +groupId);
    }
    return group;
};
export const getStudentsOfGroupFromLocalStorage = (groupId) => {
    let students = [];
    if (localStorage.getItem('reduxState')) {
        if (JSON.parse(localStorage.getItem('reduxState')).students.groupId
            && JSON.parse(localStorage.getItem('reduxState')).students.groupId === groupId) {
            students = JSON.parse(localStorage.getItem('reduxState')).students.students;
        }
    }
    return students;
};
export const getStudentFromLocalStorage = (studentId) => {
    let student = null;
    if (localStorage.getItem('reduxState')) {
        if (JSON.parse(localStorage.getItem('reduxState')).students.students) {
            student = JSON.parse(localStorage.getItem('reduxState')).students.students.find(student => student.id === +studentId);
        }
    }
    return student;
};
