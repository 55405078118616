export const FETCH_STUDENT_REPORT = 'FETCH_STUDENT_REPORT';
export const FETCH_STUDENT_REPORT_LOADING = 'FETCH_STUDENT_REPORT_LOADING';
export const FETCH_STUDENT_REPORT_ERROR = 'FETCH_STUDENT_REPORT_ERROR';
export const CREATE_ASSESSMENT_LOADING = 'CREATE_ASSESSMENT_LOADING';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const CREATE_ASSESSMENT_ERROR = 'CREATE_ASSESSMENT_ERROR';
export const SAVE_ASSESSMENT_DETAILS_LOADING = 'SAVE_ASSESSMENT_DETAILS_LOADING';
export const SAVE_ASSESSMENT_DETAILS = 'SAVE_ASSESSMENT_DETAILS';
export const SAVE_ASSESSMENT_DETAILS_ERROR = 'SAVE_ASSESSMENT_DETAILS_ERROR';
export const UPDATE_ASSESSMENT_DETAIL_REPORT_STATUS_IN_STORE = 'UPDATE_ASSESSMENT_DETAIL_REPORT_STATUS_IN_STORE';

