export const LEVEL_ONE = 1;
export const LEVEL_TWO = 2;
export const LEVEL_THREE = 3;
export const LEVEL_FOUR = 4;
export const RANK_ZERO = 0;
export const RANK_ONE = 1;
export const RANK_TWO = 2;

export const FIELD_OUTCOMES_TABLE = [
  {key: 'name', label: 'Outcome'},
  {key: 'created_at', label: 'Created Date'},
  {key: 'actionEdit', label: ''},
  {key: 'actionRemove', label: ''},
]
