import * as actionRoleType from '../../constants/actionRoleType';
import {addNewRole, removeRole, updateRoleByRoleId} from '../../utils/services/roleService';

var initialState = {
  pending: false,
  removeRolePending: false,
  addRolePending: false,
  updateRolePending: false,
  roles: [],
  error: null
};
var rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionRoleType.FETCH_ROLES_IS_LOADING:
      return {
        ...state,
        pending: true
      };
    case actionRoleType.FETCH_ROLES:
      return {
        ...state,
        roles: action.roles,
        pending: false
      };
    case actionRoleType.FETCH_ROLES_IS_ERROR:
      return {
        ...state,
        roles: [],
        pending: false,
        error: action.error
      };
    case actionRoleType.REMOVE_ROLE_IS_LOADING:
      return {
        ...state,
        removeRolePending: true
      };
    case actionRoleType.REMOVE_ROLE:
      let roles = removeRole(action.role.id, state.roles);
      return {
        ...state,
        removeRolePending: false,
        roles: roles
      };
    case actionRoleType.REMOVE_ROLE_IS_ERROR:
      return {
        ...state,
        removeRolePending: false,
        error: action.error
      };
    case actionRoleType.ADD_ROLE_IS_LOADING:
      return {
        ...state,
        addRolePending: true
      };
    case actionRoleType.ADD_ROLE:
      addNewRole(action.role, state.roles);
      return {
        ...state,
        addRolePending: false,
        roles: state.roles
      };
    case actionRoleType.ADD_ROLE_IS_ERROR:
      return {
        ...state,
        addRolePending: false,
        error: action.error
      };
    case actionRoleType.UPDATE_ROLE_IS_LOADING:
      return {
        ...state,
        updateRolePending: true
      };
    case actionRoleType.UPDATE_ROLE:
      state.roles = updateRoleByRoleId(action.role, state.roles);
      return {
        ...state,
        updateRolePending: false,
        roles: state.roles
      };
    case actionRoleType.UPDATE_ROLE_IS_ERROR:
      return {
        ...state,
        updateRolePending: false,
        error: action.error
      };
    default:
      return {...state};
  }
};
export default rolesReducer;
