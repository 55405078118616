import * as actionGroupsType from '../../constants/actionGroupsType';
var initialState = {
  pending: false,
  groups: [],
  error: null
};

var groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionGroupsType.FETCH_GROUPS_LOADING:
      return {
        ...state,
        groups: [],
        pending: true
      }
    case actionGroupsType.FETCH_GROUPS:
      return {
        ...state,
        pending: false,
        groups: action.groups
      }
    case actionGroupsType.FETCH_GROUPS_ERROR:
      return {
        ...state,
        pending: false,
        groups: [],
        error: action.error
      }
    default:
      return {...state};
  }
}
export default groupsReducer;
