import { createStore , applyMiddleware} from 'redux';
import rootReducer from '../redux/reducers/rootReducer';
import * as localStorage from '../utils/localStorage';
import thunk from 'redux-thunk';

const store = createStore(rootReducer, localStorage.loadFromLocalStorage(), applyMiddleware(thunk))
store.subscribe(() => {
  localStorage.saveReduxStateToLocalStorage(store.getState());
});


export default store
