import * as RoleConstants from '../../constants/role';

export const isRoleAdmin = (user) => {
    return +user.role === RoleConstants.IS_ADMIN;
};
export const isRoleCoach = (user) => {
    return +user.role === RoleConstants.IS_USER;
};
export const isRoleStudent = (user) => {
    return +user.role === RoleConstants.IS_STUDENT;
};
export const roleUser = (user) => {
    if (+user.role === RoleConstants.IS_STUDENT) {
        return RoleConstants.IS_STUDENT;
    }
    if (+user.role === RoleConstants.IS_USER) {
        return RoleConstants.IS_USER;
    }
    return RoleConstants.IS_ADMIN;
};
