import role from '../../model/role';
import * as DateTime from '../dateTime';
import {getCurrentDate} from '../dateTime';

export const removeRole = (id, roles) => {
    roles = roles.filter(function (role) {
        return role && (+role.id !== +id);
    });
    return roles;
};

export const newRole = (name, description) => {
    let newRole = role;
    newRole.name = name;
    newRole.description = description;
    newRole.created_at = DateTime.getCurrentDate('Y-m-d');
    newRole.updated_at = DateTime.getCurrentDate('Y-m-d');
    return newRole;
};

export const validationRoleName = (name, roles) => {
    let role = roles.filter(function (role) {
        return role && role.name.replace(/\s/g, '') === name.replace(/\s/g, '');
    });
    return role.length <= 0;
};

export const addNewRole = (newRole, roles) => {
    roles.push(newRole);
};

export const getDataUpdateRole = (name, description) => {
    let newRole = role;
    newRole.name = name;
    newRole.description = description;
    newRole.updated_at = DateTime.getCurrentDate('Y-m-d');
    return newRole;
};

export const validateUpdateRoleName = (roleId, name, roles) => {
    let role = roles.filter(function (role) {
        return (role.name.replace(/\s/g, '') === name.replace(/\s/g, '')) && (role.id !== roleId);
    });
    return role.length !== 0;
};

export const updateRoleByRoleId = (roleUpdate, roles) => {
    let roleIndex = roles.findIndex(function (role) {
        return role && (+role.id === +roleUpdate.id);
    });
    roles[roleIndex] = roleUpdate;
    return roles;
};

export const setPermissionOfRole = (roleId, permission, permissionsOfRole) => {
    let index = permissionsOfRole.findIndex(function (rolePermission) {
        return +rolePermission.permission_id === permission.id;
    });
    if (index !== -1) {
        permissionsOfRole = removePermissionInRolePermissions(permission, permissionsOfRole);
    } else {
        permissionsOfRole = addPermissionIntoRolePermissions(roleId, permission, permissionsOfRole);
    }
    return permissionsOfRole;
};

export const addPermissionIntoRolePermissions = (roleId, permission, permissionsOfRole) => {
    let newRolePermission = {
        role_id: +roleId,
        permission_id: +permission.id,
        created_at: getCurrentDate('Y-m-d'),
        updated_at: getCurrentDate('Y-m-d')
    };
    permissionsOfRole.push(newRolePermission);
    return permissionsOfRole;
};

export const removePermissionInRolePermissions = (permission, permissionsOfRole) => {
    permissionsOfRole = permissionsOfRole.filter(function (rolePermission) {
        return +rolePermission.permission_id !== +permission.id;
    });
    return permissionsOfRole;
};
