import * as actionSettingType from '../../constants/actionSettingType';

export const changeStatusActionRequest = (status) => {
  return (dispatch) => {
    dispatch(changeStatusAction(status));
  }
}

export const changeStatusAction = (status) => {
  return {
    type: actionSettingType.CHANGE_STATUS_ACTION,
    status: status
  }
}
