import {publicRouters} from "../../routes";

export const isPublic = (pathname) => {
  let firstStringIndex = '/students/'.length;
  let lastStringIndex = pathname.indexOf("/profile");
  let code =  pathname.slice(firstStringIndex, lastStringIndex)
  let pathCurrent = pathname.replace(code, ':code');
  let router = publicRouters.filter(function (router) {
    return router.path === pathCurrent
  })
  return !!router.length
}
