export const FETCH_ROLES_IS_LOADING = 'FETCH_ROLES_IS_LOADING';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_IS_ERROR = 'FETCH_ROLES_IS_ERROR';
export const REMOVE_ROLE_IS_LOADING = 'REMOVE_ROLE_IS_LOADING';
export const REMOVE_ROLE = 'REMOVE_ROLE';
export const REMOVE_ROLE_IS_ERROR = 'REMOVE_ROLE_IS_ERROR';
export const ADD_ROLE_IS_LOADING = 'ADD_ROLE_IS_LOADING';
export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_IS_ERROR = 'ADD_ROLE_IS_ERROR';
export const UPDATE_ROLE_IS_LOADING = 'UPDATE_ROLE_IS_LOADING';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_IS_ERROR = 'UPDATE_ROLE_IS_ERROR';
export const FETCH_ROLE_IS_LOADING = 'FETCH_ROLE_IS_LOADING';
export const FETCH_ROLE = 'FETCH_ROLE';
export const FETCH_ROLE_IS_ERROR = 'FETCH_ROLE_IS_ERROR';
export const SET_PERMISSION_OF_ROLE_LOADING = 'SET_PERMISSION_OF_ROLE_LOADING';
export const SET_PERMISSION_OF_ROLE = 'SET_PERMISSION_OF_ROLE';
export const SET_PERMISSION_OF_ROLE_ERROR = 'SET_PERMISSION_OF_ROLE_ERROR';
export const UPDATE_PERMISSIONS_OF_ROLE_LOADING = 'UPDATE_PERMISSIONS_OF_ROLE_LOADING';
export const UPDATE_PERMISSIONS_OF_ROLE = 'UPDATE_PERMISSIONS_OF_ROLE';
export const UPDATE_PERMISSIONS_OF_ROLE_ERROR = 'UPDATE_PERMISSIONS_OF_ROLE_ERROR';