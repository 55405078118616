import * as actionPermissionType from '../../constants/actionPermissionType';

var initialState = {
  pending: false,
  permissions: [],
  error: null
};
var permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionPermissionType.FETCH_PERMISSIONS_LOADING:
      return {
        ...state,
        pending: true
      };
    case actionPermissionType.FETCH_PERMISSIONS:
      return {
        ...state,
        pending: false,
        permissions: action.permissions
      };
    case actionPermissionType.FETCH_PERMISSIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return {...state};
  }
};
export default permissionsReducer;
