import * as actionAssessmentType from '../../constants/actionAssessmentType';

var initialState = {
    pending: false,
    studentId: null,
    removeAssessmentLoading: false,
    assessment_details: [],
    error: null
};
var assessmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionAssessmentType.ADD_ASSESSMENT_DETAILS:
            return {
                ...state,
                pending: false,
                studentId: action.studentId,
                assessment_details: action.assessmentDetails
            };
        case actionAssessmentType.ADD_ASSESSMENT:
            let assessment_detail = state.assessment_details.find(assessment_detail => +assessment_detail.outcome_id === +action.assessment.outcome_id && +assessment_detail.level === +action.assessment.level);
            assessment_detail.report = +action.assessment.report;
            return {
                ...state,
                pending: false,
                studentId: action.studentId,
                assessment_details: [...state.assessment_details]
            };
        case actionAssessmentType.REMOVE_ASSESSMENT_LOADING:
            return {
                ...state,
                removeAssessmentLoading: true
            };
        case actionAssessmentType.REMOVE_ASSESSMENT:
            return {
                ...state,
                removeAssessmentLoading: false,
                assessment_details: []
            };
        case actionAssessmentType.REMOVE_ASSESSMENT_ERROR:
            return {
                ...state,
                removeAssessmentLoading: false
            };
        case actionAssessmentType.FETCH_LAST_REPORT_OF_COACH:
            state.assessment_details = action.of_coach;
            state.pending = false;
            state.studentId = action.studentId;
            return {
                ...state,
                removeAssessmentLoading: false
            };
        case actionAssessmentType.FETCH_LAST_REPORT_OF_STUDENT:
            state.assessment_details = action.of_student;
            state.pending = false;
            state.studentId = action.studentId;
            return {
                ...state,
                removeAssessmentLoading: false
            };
        case actionAssessmentType.FETCH_LAST_REPORT_ERROR:
            state.pending = false;
            state.error = action.error;
            state.assessment_details = [];
            state.studentId = action.studentId;
            return {
                ...state,
                removeAssessmentLoading: false
            };
        default:
            return {...state};
    }
};
export default assessmentReducer;
