import * as actionOauthType from '../../constants/actionOauthType';
var initialState = {
  fetchOauthLoading: false,
  createAppClientLoading: false,
  updateAppClientLoading: false,
  removeAppClientLoading: false,
  oauth: [],
  error: null
};

var oauthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionOauthType.FETCH_OAUTH_LOADING:
      return {
        ...state,
        fetchOauthLoading: true,
      }
    case actionOauthType.FETCH_OAUTH:
      return {
        ...state,
        oauth: action.oauth,
        fetchOauthLoading: false,
      }
    case actionOauthType.FETCH_OAUTH_ERROR:
      return {
        ...state,
        fetchOauthLoading: false,
        error: action.error
      }
    case actionOauthType.CREATE_APP_CLIENT_LOADING:
      return {
        ...state,
        createAppClientLoading: true,
      }
    case actionOauthType.CREATE_APP_CLIENT:
      state.oauth.push(action.oauth);
      return {
        ...state,
        oauth: state.oauth,
        createAppClientLoading: false,
      }
    case actionOauthType.CREATE_APP_CLIENT_ERROR:
      return {
        ...state,
        createAppClientLoading: false,
        error: action.error
      }
    case actionOauthType.EDIT_APP_CLIENT_LOADING:
      return {
        ...state,
        updateAppClientLoading: true,
      }
    case actionOauthType.EDIT_APP_CLIENT:
      let oauthIndex = state.oauth.findIndex(oauth => +oauth.id === +action.oauthId);
      state.oauth[oauthIndex].name = action.oauth.name;
      state.oauth[oauthIndex].redirect = action.oauth.redirect;
      return {
        ...state,
        oauth: state.oauth,
        updateAppClientLoading: false,
      }
    case actionOauthType.EDIT_APP_CLIENT_ERROR:
      return {
        ...state,
        updateAppClientLoading: false,
        error: action.error
      }
    case actionOauthType.REMOVE_APP_CLIENT_LOADING:
      return {
        ...state,
        removeAppClientLoading: true,
      }
    case actionOauthType.REMOVE_APP_CLIENT:
      state.oauth.filter(function(oauth) {
        return +oauth.id !== +action.oauthId
      })
      return {
        ...state,
        oauth: state.oauth,
        removeAppClientLoading: false,
      }
    case actionOauthType.REMOVE_APP_CLIENT_ERROR:
      return {
        ...state,
        removeAppClientLoading: false,
        error: action.error
      }
    default:
      return {...state};
  }
}
export default oauthReducer;
