export const FETCH_COMPETENCES = "FETCH_COMPETENCES";
export const FETCH_COMPETENCES_LOADING = "FETCH_COMPETENCES_LOADING";
export const FETCH_COMPETENCES_ERROR = "FETCH_COMPETENCES_ERROR";

export const ADD_COMPETENCE = "ADD_COMPETENCE";
export const ADD_COMPETENCE_LOADING = "ADD_COMPETENCE_LOADING";
export const ADD_COMPETENCE_ERROR = "ADD_COMPETENCE_ERROR";

export const EDIT_COMPETENCE = "EDIT_COMPETENCE";
export const EDIT_COMPETENCE_LOADING = "EDIT_COMPETENCE_LOADING";
export const EDIT_COMPETENCE_ERROR = "EDIT_COMPETENCE_ERROR";

export const REMOVE_COMPETENCE_INTO_COMPETENCES = "REMOVE_COMPETENCE_INTO_COMPETENCES";
export const REMOVE_VERSION_COMPETENCE_INTO_COMPETENCES = "REMOVE_VERSION_COMPETENCE_INTO_COMPETENCES";

export const ADD_NEW_VERSION_INTO_FRAMEWORK = 'ADD_NEW_VERSION_INTO_FRAMEWORK';
