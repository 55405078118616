import * as actionAuthType from '../../constants/actionAuthType';

var initialState = {
  loginPending: false,
  logoutPending: false,
  user: {},
  access_token: {},
  error: null,
  centers: []
};
var authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionAuthType.LOGIN_LOADING:
      return {
        ...state,
        loginPending: true,
        user: {},
        centers: [],
        access_token: {}
      };
    case actionAuthType.LOGIN:
      return {
        ...state,
        loginPending: false,
        user: action.user,
        centers: action.centers,
        access_token: action.access_token
      };
    case actionAuthType.LOGIN_ERROR:
      return {
        ...state,
        loginPending: false,
        user: {},
        centers: [],
        access_token: {},
        error: action.error
      };
    default:
      return {...state};
  }
};
export default authReducer;
