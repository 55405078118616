import {combineReducers} from 'redux';
import competences from './competencesReducer';
import competence from './conpetenceReducer';
import outcomes from './outcomesReducer';
import auth from './authReducer';
import groups from './groupsReducer';
import group from './groupReducer';
import students from './studentsReducer';
import student from './studentReducer';
import report from './reportReducer';
import assessment from './assessmentReducer';
import profile from './profileReducer';
import setting from './settingReducer';
import oauth from './oauthReducer';
import roles from './rolesReducer';
import role from './roleReducer';
import permissions from './permissionReducer';
import users from './usersReducer';
import certificates from './certificatesReducer';

const rootReducer = combineReducers({
  competences,
  competence,
  outcomes,
  auth,
  groups,
  group,
  students,
  student,
  report,
  assessment,
  profile,
  setting,
  oauth,
  roles,
  role,
  permissions,
  users,
  certificates
});
export default rootReducer;
