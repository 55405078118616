export const hasPermission = (permission, permissions) => {
  if (!permissions.length) {
    return false;
  } else {
    let hasPermission = permissions.find(userPermission => userPermission.name === permission);
    return !!hasPermission;
  }
};
export const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, []);
};
export const getCategories = (permissionsAfterGroupBy) => {
  return permissionsAfterGroupBy[0];
};
export const getPermissions = (permissionsAfterGroupBy) => {
  return permissionsAfterGroupBy[1];
};
export const canAddOutcome = (disabledAction, permission, isAddOutcome) => {
  return (disabledAction && permission && isAddOutcome);
};
