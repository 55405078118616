import * as actionReportType from '../../constants/actionReportType';
import * as outcomeService from '../../utils/services/outcome';
import {addNewAssessment} from '../../utils/services/assessment';
import * as roleConstants from '../../constants/role';
import {REPORT_OF_COACH, REPORT_OF_STUDENT} from '../../constants/report';

var initialState = {
  pending: false,
  studentId: null,
  createAssessmentLoading: false,
  saveAssessmentLoading: false,
  report_coach: {
    reports: [],
    assessments: []
  },
  report_student: {
    reports: [],
    assessments: []
  },
  error: null
};
var reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionReportType.FETCH_STUDENT_REPORT_LOADING:
      return {
        ...state,
        pending: true,
        error: null
      };
    case actionReportType.FETCH_STUDENT_REPORT:
      if (+action.role === roleConstants.IS_USER) {
        state.report_coach.reports = outcomeService.sortTheArrayOutcomes(action.report.reports);
        state.report_coach.assessments = action.report.assessments;
        state.studentId = action.studentId;
      }
      if (+action.role === roleConstants.IS_STUDENT) {
        state.report_student.reports = outcomeService.sortTheArrayOutcomes(action.report.reports);
        state.report_student.assessments = action.report.assessments;
        state.studentId = action.studentId;
      }
      return {
        ...state,
        createAssessmentLoading: false,
        saveAssessmentLoading: false,
        pending: false,
        error: null
      };
    case actionReportType.FETCH_STUDENT_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case actionReportType.CREATE_ASSESSMENT_LOADING:
      return {
        ...state,
        createAssessmentLoading: true,
        report: state.report
      };
    case actionReportType.CREATE_ASSESSMENT:
      if (+action.role === roleConstants.IS_USER) {
        state.report_coach = addNewAssessment(state.report_coach, action);
      }
      if (+action.role === roleConstants.IS_STUDENT) {
        state.report_student = addNewAssessment(state.report_student, action);
      }
      return {
        ...state,
        createAssessmentLoading: false
      };
    case actionReportType.CREATE_ASSESSMENT_ERROR:
      return {
        ...state,
        createAssessmentLoading: false,
        error: action.error
      };
    case actionReportType.SAVE_ASSESSMENT_DETAILS_LOADING:
      return {
        ...state,
        saveAssessmentLoading: true
      };
    case actionReportType.SAVE_ASSESSMENT_DETAILS:
      return {
        ...state,
        saveAssessmentLoading: false
      };
    case actionReportType.SAVE_ASSESSMENT_DETAILS_ERROR:
      return {
        ...state,
        saveAssessmentLoading: false,
        error: action.error
      };
    case actionReportType.UPDATE_ASSESSMENT_DETAIL_REPORT_STATUS_IN_STORE:
      if (action.role === REPORT_OF_STUDENT) {
        let reportIndex = state.report_student.reports.findIndex(report => report.id === action.reportId);
        state.report_student.reports[reportIndex].assessmentDetails[state.report_student.reports[reportIndex].assessmentDetails.length - 1].report = +action.statusReport;
      } else if (action.role === REPORT_OF_COACH) {
        let reportIndex = state.report_coach.reports.findIndex(report => report.id === action.reportId);
        state.report_coach.reports[reportIndex].assessmentDetails[state.report_coach.reports[reportIndex].assessmentDetails.length - 1].report = +action.statusReport;
      }
      return {
        ...state,
        pending: false,
        error: null
      };
    default:
      return {...state};
  }
};
export default reportReducer;
