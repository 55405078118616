export const FETCH_PROFILE_LOADING = "FETCH_PROFILE_LOADING";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_ERROR = "FETCH_PROFILE_ERROR";

export const CREATE_NOTE_OF_COACH_LOADING = 'CREATE_NOTE_OF_COACH_LOADING';
export const CREATE_NOTE_OF_COACH = 'CREATE_NOTE_OF_COACH';
export const CREATE_NOTE_OF_COACH_ERROR = 'CREATE_NOTE_OF_COACH_ERROR';

export const UPDATE_NOTE_OF_COACH_LOADING = 'UPDATE_NOTE_OF_COACH_LOADING';
export const UPDATE_NOTE_OF_COACH = 'UPDATE_NOTE_OF_COACH';
export const UPDATE_NOTE_OF_COACH_ERROR = 'UPDATE_NOTE_OF_COACH_ERROR';

export const CREATE_OBJECTIVE_LOADING = 'CREATE_OBJECTIVE_LOADING';
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_ERROR = 'CREATE_OBJECTIVE_ERROR';

export const EDIT_OBJECTIVE_LOADING = 'EDIT_OBJECTIVE_LOADING';
export const EDIT_OBJECTIVE = 'EDIT_OBJECTIVE';
export const EDIT_OBJECTIVE_ERROR = 'EDIT_OBJECTIVE_ERROR';

export const CREATE_INTERESTS_LOADING = 'CREATE_INTERESTS_LOADING';
export const CREATE_INTERESTS = 'CREATE_INTERESTS';
export const CREATE_INTERESTS_ERROR = 'CREATE_INTERESTS_ERROR';

export const EDIT_INTERESTS_LOADING = 'EDIT_INTERESTS_LOADING';
export const EDIT_INTERESTS = 'EDIT_INTERESTS';
export const EDIT_INTERESTS_ERROR = 'EDIT_INTERESTS_ERROR';

export const CREATE_PROJECT_LOADING = 'CREATE_PROJECT_LOADING';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';

export const EDIT_PROJECT_LOADING = 'EDIT_PROJECT_LOADING';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const EDIT_PROJECT_ERROR = 'EDIT_PROJECT_ERROR';

export const REMOVE_PROJECT_LOADING = 'REMOVE_PROJECT_LOADING';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const REMOVE_PROJECT_ERROR = 'REMOVE_PROJECT_ERROR';

export const CREATE_LANGUAGES_LOADING = 'CREATE_LANGUAGES_LOADING';
export const CREATE_LANGUAGES = 'CREATE_LANGUAGES';
export const CREATE_LANGUAGES_ERROR = 'CREATE_LANGUAGES_ERROR';
