export const FETCH_OUTCOMES = "FETCH_OUTCOMES";
export const FETCH_OUTCOMES_LOADING = "FETCH_OUTCOMES_LOADING";
export const FETCH_OUTCOMES_ERROR = "FETCH_OUTCOMES_ERROR";

export const ADD_OUTCOME = "ADD_OUTCOME";
export const ADD_OUTCOME_LOADING = "ADD_OUTCOME_LOADING";
export const ADD_OUTCOME_ERROR = "ADD_OUTCOME_ERROR";

export const REMOVE_OUTCOMES_OF_COMPETENCE = "REMOVE_OUTCOMES_OF_COMPETENCE";

export const EDIT_OUTCOME = "EDIT_OUTCOME";
export const EDIT_OUTCOME_LOADING = "EDIT_OUTCOME_LOADING";
export const EDIT_OUTCOME_ERROR = "EDIT_OUTCOME_ERROR";

export const REMOVE_OUTCOME = "REMOVE_OUTCOME";
export const REMOVE_OUTCOME_LOADING = "REMOVE_OUTCOME_LOADING";
export const REMOVE_OUTCOME_ERROR = "REMOVE_OUTCOME_ERROR";
