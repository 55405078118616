export const FETCH_CERTIFICATES = 'FETCH_CERTIFICATES';
export const FETCH_CERTIFICATES_IS_LOADING = 'FETCH_CERTIFICATES_IS_LOADING';
export const FETCH_CERTIFICATES_IS_ERROR = 'FETCH_CERTIFICATES_IS_ERROR';
export const CREATE_CERTIFICATE_LOADING = 'CREATE_CERTIFICATE_LOADING';
export const CREATE_CERTIFICATE = 'CREATE_CERTIFICATE';
export const CREATE_CERTIFICATE_ERROR = 'CREATE_CERTIFICATE_ERROR';
export const REMOVE_CERTIFICATE_LOADING = 'REMOVE_CERTIFICATE_LOADING';
export const REMOVE_CERTIFICATE = 'REMOVE_CERTIFICATE';
export const REMOVE_CERTIFICATE_ERROR = 'REMOVE_CERTIFICATE_ERROR';
export const UPDATE_CERTIFICATE_LOADING = 'UPDATE_CERTIFICATE_LOADING';
export const UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE';
export const UPDATE_CERTIFICATE_ERROR = 'UPDATE_CERTIFICATE_ERROR';
