export const GET_COMPETENCE = "GET_COMPETENCE";
export const GET_COMPETENCE_LOADING = "GET_COMPETENCE_LOADING";
export const GET_COMPETENCE_ERROR = "GET_COMPETENCE_ERROR";

export const REMOVE_COMPETENCE = "REMOVE_COMPETENCE";
export const REMOVE_COMPETENCE_LOADING = "REMOVE_COMPETENCE_LOADING";
export const REMOVE_COMPETENCE_ERROR = "REMOVE_COMPETENCE_ERROR";

export const REMOVE_VERSION_COMPETENCE = "REMOVE_COMPETENCE_ERROR";

export const SAVE_AS_A_NEW_VERSION_LOADING = "SAVE_AS_A_NEW_VERSION_LOADING";
export const SAVE_AS_A_NEW_VERSION = "SAVE_AS_A_NEW_VERSION";
export const SAVE_AS_A_NEW_VERSION_ERROR = "SAVE_AS_A_NEW_VERSION_ERROR";
