import * as actionStudentType from '../../constants/actionStudentType';

var initialState = {
  pending: false,
  student: null,
  error: null,
  exportCompetenceOfStudentIsPending: false
};
var studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionStudentType.FETCH_STUDENT_LOADING:
      return {
        ...state,
        pending: true,
        student: null
      };
    case actionStudentType.FETCH_STUDENT:
      return {
        ...state,
        pending: false,
        student: action.student
      };
    case actionStudentType.FETCH_STUDENT_ERROR:
      return {
        ...state,
        pending: false,
        student: null,
        error: action.error
      };
    case actionStudentType.EXPORT_COMPETENCE_OF_STUDENT_LOADING:
      return {
        ...state,
        exportCompetenceOfStudentIsPending: true
      };
    case actionStudentType.EXPORT_COMPETENCE_OF_STUDENT:
      return {
        ...state,
        exportCompetenceOfStudentIsPending: false
      };
    case actionStudentType.EXPORT_COMPETENCE_OF_STUDENT_ERROR:
      return {
        ...state,
        exportCompetenceOfStudentIsPending: false,
        error: action.error
      };
    default:
      return {...state};
  }
};
export default studentReducer;
