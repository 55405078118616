import React from 'react';
import {connect} from 'react-redux';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import './header.css';
import {useKeycloak} from '@react-keycloak/web';
import * as localStorageService from "../utils/localStorage";

const TheHeaderDropdown = (prods) => {
  const { keycloak } = useKeycloak()

  const logout = () => {
    localStorageService.clear();
    keycloak.logout().then(res => {});
  }

  const avatar = () => {
    return '/avatars/noAvatar.jpg';
  }
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className={'pl-1 pr-1 color-white'}>{prods.user ? prods.user.name : ''}</div>
        <div className="c-avatar">
          <CImg
            src={avatar()}
            className="c-avatar-img"
            alt="avatar"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem onClick={logout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}
const mapStateToProds = (state) => {
  return {
    user: state.auth.user,
    setting: state.setting,
  }
}
export default connect (mapStateToProds, null) (TheHeaderDropdown)
