import * as actionStudentsType from '../../constants/actionStudentsType';
var initialState = {
  pending: false,
  groupId: null,
  students: [],
  error: null
};

var studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionStudentsType.FETCH_STUDENTS_LOADING:
      return {
        ...state,
        groupId: null,
        students: [],
        pending: true
      }
    case actionStudentsType.FETCH_STUDENTS:
      return {
        ...state,
        pending: false,
        groupId: action.groupId,
        students: action.students
      }
    case actionStudentsType.FETCH_STUDENTS_ERROR:
      return {
        ...state,
        pending: false,
        groupId: null,
        students: [],
        error: action.error
      }
    default:
      return {...state};
  }
}
export default studentsReducer;
