import * as actionGroupType from '../../constants/actionGroupType';
import * as actionGroupsType from '../../constants/actionGroupsType';
var initialState = {
  pending: false,
  createAssessmentsGroupPending: false,
  exportReportsGroupPending: false,
  group: {},
  error: null
};

var groupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionGroupType.FETCH_GROUP:
      return {
        ...state,
        pending: false,
        group: action.group,
      }
    case actionGroupsType.CREATE_ASSESSMENTS_GROUP_LOADING:
      return {
        ...state,
        createAssessmentsGroupPending: true
      }
    case actionGroupsType.CREATE_ASSESSMENTS_GROUP:
      return {
        ...state,
        createAssessmentsGroupPending: false,
      }
    case actionGroupsType.CREATE_ASSESSMENTS_GROUP_ERROR:
      return {
        ...state,
        createAssessmentsGroupPending: false,
        error: action.error
      }
    case actionGroupsType.EXPORT_REPORTS_GROUP_LOADING:
      return {
        ...state,
        exportReportsGroupPending: true
      }
    case actionGroupsType.EXPORT_REPORTS_GROUP:
      return {
        ...state,
        exportReportsGroupPending: false,
      }
    case actionGroupsType.EXPORT_REPORTS_GROUP_ERROR:
      return {
        ...state,
        exportReportsGroupPending: false,
        error: action.error
      }
    default:
      return {...state};
  }
}
export default groupReducer;
