export const FETCH_OAUTH_LOADING = "FETCH_OAUTH_LOADING";
export const FETCH_OAUTH = "FETCH_OAUTH";
export const FETCH_OAUTH_ERROR = "FETCH_OAUTH_ERROR";

export const CREATE_APP_CLIENT_LOADING = "CREATE_APP_CLIENT_LOADING";
export const CREATE_APP_CLIENT = "CREATE_APP_CLIENT";
export const CREATE_APP_CLIENT_ERROR = "CREATE_APP_CLIENT_ERROR";

export const EDIT_APP_CLIENT_LOADING = "EDIT_APP_CLIENT_LOADING";
export const EDIT_APP_CLIENT = "EDIT_APP_CLIENT";
export const EDIT_APP_CLIENT_ERROR = "EDIT_APP_CLIENT_ERROR";

export const REMOVE_APP_CLIENT_LOADING = "REMOVE_APP_CLIENT_LOADING";
export const REMOVE_APP_CLIENT = "REMOVE_APP_CLIENT";
export const REMOVE_APP_CLIENT_ERROR = "REMOVE_APP_CLIENT_ERROR";
