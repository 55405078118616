import * as outcomeConstants from '../../constants/outcome';

export const addOutcome = (storeOutcomes, action) => {
  if (+action.level === outcomeConstants.LEVEL_ONE) {
    storeOutcomes.outcomes.push(action.outcome);
  }
  if (+action.level === outcomeConstants.LEVEL_TWO) {
    let outcomeParentIndex = storeOutcomes.outcomes.findIndex(outcome => outcome.id === action.outcome.parent_level_id);
    storeOutcomes.outcomes[outcomeParentIndex].outcome_level_two.push(action.outcome);
  }
  if (+action.level === outcomeConstants.LEVEL_THREE) {
    storeOutcomes.outcomes.forEach(outcome => {
      outcome.outcome_level_two.forEach(outcomeLevelTwo => {
        if (outcomeLevelTwo.id === action.outcome.parent_level_id) {
          outcomeLevelTwo.outcome_level_three.push(action.outcome);
        }
      });
    });
  }
  if (+action.level === outcomeConstants.LEVEL_FOUR) {
    storeOutcomes.outcomes.forEach(outcome => {
      outcome.outcome_level_two.forEach(outcomeLevelTwo => {
        outcomeLevelTwo.outcome_level_three.forEach(outcomeLevelThree => {
          if (outcomeLevelThree.id === action.outcome.parent_level_id) {
            outcomeLevelThree.outcome_level_four.push(action.outcome);
          }
        });
      });
    });
  }
  return storeOutcomes;
};
export const sortTheArrayOutcomes = (outcomes) => {
  let newOutcomes = [];
  if (Array.isArray(outcomes)) {
    outcomes.forEach(function (outcomeLevelsOne, indexLevelsOne) {
      outcomeLevelsOne.level = outcomeConstants.LEVEL_ONE;
      outcomeLevelsOne.index = (indexLevelsOne + 1);
      newOutcomes.push(outcomeLevelsOne);
      outcomeLevelsOne.outcome_level_two.forEach(function (outcomeLevelsTwo, indexLevelsTwo) {
        outcomeLevelsTwo.level = outcomeConstants.LEVEL_TWO;
        outcomeLevelsTwo.index = (indexLevelsOne + 1) + '.' + (indexLevelsTwo + 1);
        newOutcomes.push(outcomeLevelsTwo);
        outcomeLevelsTwo.outcome_level_three.forEach(function (outcomeLevelsThree, indexLevelsThree) {
          outcomeLevelsThree.level = outcomeConstants.LEVEL_THREE;
          outcomeLevelsThree.index = (indexLevelsOne + 1) + '.' + (indexLevelsTwo + 1) + '.' + (indexLevelsThree + 1);
          newOutcomes.push(outcomeLevelsThree);
          outcomeLevelsThree.outcome_level_four.forEach(function (outcomeLevelsFour, indexLevelsFour) {
            outcomeLevelsFour.level = outcomeConstants.LEVEL_FOUR;
            outcomeLevelsFour.index = (indexLevelsOne + 1) + '.' + (indexLevelsTwo + 1) + '.' + (indexLevelsThree + 1) + '.' + (indexLevelsFour + 1);
            newOutcomes.push(outcomeLevelsFour);
          });
        });
      });
    });
  }
  return newOutcomes.flat(Infinity);
};
export const newOutcome = (name, level, rank, position, parent_level_id) => {
  if (+level === outcomeConstants.LEVEL_ONE) {
    parent_level_id = null;
  }
  return {name: name, level: level, rank: rank, position: position, parent_level_id: parent_level_id};
};
export const editOutcome = (outcomes, action) => {
  let outcome = sortTheArrayOutcomes(outcomes.outcomes).find(outcome => outcome.id === action.outcome.id && outcome.level === action.level);
  outcome.name = action.outcome.name;
  outcome.rank = action.outcome.rank;
  return outcomes;
};



