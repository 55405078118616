import * as actionOutcomesType from '../../constants/actionOutcomesType';
import * as outcomeService from '../../utils/services/outcome';
var initialState = {
  pending: false,
  competenceId: null,
  addOutcomePending: false,
  editOutcomePending: false,
  removeOutcomePending: false,
  outcomes: [],
  error: null
};

var outcomesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionOutcomesType.FETCH_OUTCOMES_LOADING:
      return {
        ...state,
        competenceId: null,
        outcomes: [],
        pending: true
      }
    case actionOutcomesType.FETCH_OUTCOMES:
      return {
        ...state,
        pending: false,
        competenceId: action.competenceId,
        outcomes: action.outcomes
      }
    case actionOutcomesType.FETCH_OUTCOMES_ERROR:
      return {
        ...state,
        pending: false,
        competenceId: null,
        outcomes: [],
        error: action.error
      }

    case actionOutcomesType.REMOVE_OUTCOMES_OF_COMPETENCE:
      return {
        ...state,
        pending: false,
        competenceId: null,
        outcomes: [],
      }
    case actionOutcomesType.ADD_OUTCOME:
      state.outcomes = outcomeService.addOutcome(state.outcomes, action)
      return {
        ...state,
        addOutcomePending: false,
        competenceId: action.competenceId,
        outcomes: state.outcomes
      }
    case actionOutcomesType.ADD_OUTCOME_LOADING:
      return {
        ...state,
        competenceId: state.competenceId,
        addOutcomePending: true,
        outcomes: state.outcomes
      }
    case actionOutcomesType.ADD_OUTCOME_ERROR:
      return {
        ...state,
        addOutcomePending: false,
        competenceId: state.competenceId,
        outcomes: state.outcomes,
        error: action.error
      }
    case actionOutcomesType.EDIT_OUTCOME:
      state.outcomes = outcomeService.editOutcome(state.outcomes, action)
      return {
        ...state,
        editOutcomePending: false,
        competenceId: state.competenceId,
        outcomes: state.outcomes
      }
    case actionOutcomesType.EDIT_OUTCOME_LOADING:
      return {
        ...state,
        competenceId: state.competenceId,
        editOutcomePending: true,
        outcomes: state.outcomes
      }
    case actionOutcomesType.EDIT_OUTCOME_ERROR:
      return {
        ...state,
        editOutcomePending: false,
        competenceId: state.competenceId,
        outcomes: state.outcomes,
        error: action.error
      }
    case actionOutcomesType.REMOVE_OUTCOME:
      return {
        ...state,
        removeOutcomePending: false,
        competenceId: state.competenceId,
        outcomes: state.outcomes
      }
    case actionOutcomesType.REMOVE_OUTCOME_LOADING:
      return {
        ...state,
        competenceId: state.competenceId,
        removeOutcomePending: true,
        outcomes: state.outcomes
      }
    case actionOutcomesType.REMOVE_OUTCOME_ERROR:
      return {
        ...state,
        removeOutcomePending: false,
        competenceId: state.competenceId,
        outcomes: state.outcomes,
        error: action.error
      }
    default:
      return {...state};
  }
}
export default outcomesReducer;
