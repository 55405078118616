import * as actionCompetenceType from '../../constants/actionCompetenceType';
var initialState = {
  pending: false,
  competence: {},
  removeCompetenceLoading: false,
  saveAsNewVersionLoading: false,
  error: null
};

var competenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCompetenceType.GET_COMPETENCE_LOADING:
      return {
        ...state,
        pending: true,
        competence: state.competence,
      }
    case actionCompetenceType.GET_COMPETENCE:
      return {
        ...state,
        pending: false,
        competence: action.competence,
      }
    case actionCompetenceType.GET_COMPETENCE_ERROR:
      return {
        ...state,
        pending: false,
        competence: state.competence,
        error: action.error
      }
    case actionCompetenceType.REMOVE_COMPETENCE_LOADING:
      return {
        ...state,
        removeCompetenceLoading: true,
        competence: state.competence,
      }
    case actionCompetenceType.REMOVE_COMPETENCE:
      return {
        ...state,
        removeCompetenceLoading: false,
        competence: [],
      }
    case actionCompetenceType.REMOVE_COMPETENCE_ERROR:
      return {
        ...state,
        removeCompetenceLoading: false,
        competence: state.competence,
        error: action.error
      }

    case actionCompetenceType.REMOVE_VERSION_COMPETENCE:
      state.competence.version_framework = [1];
      return {
        ...state,
        removeCompetenceLoading: false,
        competence: state.competence,
        error: action.error
      }
    case actionCompetenceType.SAVE_AS_A_NEW_VERSION_LOADING:
      return {
        ...state,
        saveAsNewVersionLoading: true,
        competence: state.competence,
      }
    case actionCompetenceType.SAVE_AS_A_NEW_VERSION:
      state.competence.version_framework.push(action.version);
      return {
        ...state,
        saveAsNewVersionLoading: false,
        competence: state.competence,
      }
    case actionCompetenceType.SAVE_AS_A_NEW_VERSION_ERROR:
      return {
        ...state,
        saveAsNewVersionLoading: false,
        competence: state.competence,
        error: action.error
      }
    default:
      return {...state};
  }
}
export default competenceReducer;
