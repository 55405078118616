import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import keycloak from "./keycloak";
import {ReactKeycloakProvider} from '@react-keycloak/web'
import {isPublic} from "./utils/services/publicRouter";
import ThePublic from "./containers/ThePublic";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"/>
  </div>
)
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));

const App = () => {
  return (
    <BrowserRouter>
      {isPublic(window.location.pathname) ?
        <ReactKeycloakProvider authClient={keycloak} initOptions={{
          onLoad: "check-sso",
        }}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route name="Public" render={props => <ThePublic {...props}/>}/>
            </Switch>
          </React.Suspense>
        </ReactKeycloakProvider>
        :
        <ReactKeycloakProvider authClient={keycloak} initOptions={{
          onLoad: "login-required",
        }}>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>}/>
              <Route name="Home" render={props => <TheLayout {...props}/>}/>
            </Switch>
          </React.Suspense>
        </ReactKeycloakProvider>}
    </BrowserRouter>
  );
}

export default App;
