import * as actionCertificateType from '../../constants/actionCertificateType';
import {removeCertificate, updateCertificate} from '../../utils/services/certificate';

var initialState = {
    pending: false,
    createCertificateLoading: false,
    certificates: [],
    error: null
};
var certificatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionCertificateType.FETCH_CERTIFICATES_IS_LOADING:
            return {
                ...state,
                pending: true
            };
        case actionCertificateType.FETCH_CERTIFICATES:
            return {
                ...state,
                certificates: action.certificates,
                pending: false
            };
        case actionCertificateType.FETCH_CERTIFICATES_IS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case actionCertificateType.CREATE_CERTIFICATE_LOADING:
            return {
                ...state,
                createCertificateLoading: true
            };
        case actionCertificateType.CREATE_CERTIFICATE:
            state.certificates.push(action.certificate);
            return {
                ...state,
                createCertificateLoading: false,
                certificates: state.certificates
            };
        case actionCertificateType.CREATE_CERTIFICATE_ERROR:
            return {
                ...state,
                createCertificateLoading: false,
                error: action.error
            };
        case actionCertificateType.REMOVE_CERTIFICATE_LOADING:
            return {
                ...state,
            };
        case actionCertificateType.REMOVE_CERTIFICATE:
            return {
                ...state,
                certificates: removeCertificate(action.certificateId, state.certificates)
            };
        case actionCertificateType.REMOVE_CERTIFICATE_ERROR:
            return {
                ...state,
                error: action.error
            };
        case actionCertificateType.UPDATE_CERTIFICATE_LOADING:
            return {
                ...state,
            };
        case actionCertificateType.UPDATE_CERTIFICATE:
            return {
                ...state,
                certificates: updateCertificate(action.certificate, state.certificates)
            };
        case actionCertificateType.UPDATE_CERTIFICATE_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return {...state};
    }
};
export default certificatesReducer;
