import React from 'react';
import {connect} from 'react-redux';
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CButton,
  CImg
} from '@coreui/react';
import * as roleService from '../utils/services/role';
import * as authService from '../utils/services/auth';
import {
  TheHeaderDropdown
} from './index';
import './header.css';
import logo from '../assets/image/logo.png';
import favicon from '../assets/image/favicon.png';
import {changeStatusActionRequest} from '../redux/actions/settingAction';
import {useLocation} from 'react-router-dom';
import {hasPermission} from '../utils/services/permission';

const TheHeader = (props) => {
  const location = useLocation();
  const isActive = (to) => {
    return location.pathname === to ? ' router-active' : ' color-white';
  };
  const profilePage = () => {
    return '/students/' + props.user.student_code + '/profile';
  };
  const reportPage = () => {
    return '/students/' + props.user.student_id + '/report';
  };
  const changeStatusAction = () => {
    props.changeStatusAction(!props.setting.disabledAction);
  };
  return (
    <CHeader withSubheader className="bg-blue-codegym">
      <CHeaderBrand className="mx-auto d-lg-none" to="/frameworks">
        <CImg src={favicon}/>
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto px-3">
        <CHeaderBrand>
          <CHeaderNavLink>
            <CImg src={logo} style={{width: '250px'}}/>
          </CHeaderNavLink>
        </CHeaderBrand>
        {
          authService.isLoggedIn()
            ?
            <>
              {
                (roleService.isRoleAdmin(props.user) || hasPermission('viewFrameworks', props.user.permissions))
                  ?
                  <CHeaderNavItem className="px-3 color-white">
                    <CHeaderNavLink className={isActive('/frameworks')} to="/frameworks">Competence
                      Management</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
              {
                (roleService.isRoleAdmin(props.user) || hasPermission('viewRoles', props.user.permissions))
                  ?
                  <CHeaderNavItem className="px-3 color-white">
                    <CHeaderNavLink className={isActive('/roles')} to="/roles">Roles Management</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
              {
                (roleService.isRoleAdmin(props.user) || hasPermission('viewUsers', props.user.permissions))
                  ?
                  <CHeaderNavItem className="px-3 color-white">
                    <CHeaderNavLink className={isActive('/users')} to="/users">Users Management</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
              {
                hasPermission('viewGroups', props.user.permissions)
                  ?
                  <CHeaderNavItem className="px-3">
                    <CHeaderNavLink className={isActive('/groups')} to="/groups">Groups</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
              {
                (roleService.isRoleStudent(props.user) && hasPermission('viewReport', props.user.permissions))
                  ?
                  <CHeaderNavItem className="px-3">
                    <CHeaderNavLink className={isActive(reportPage())} to={reportPage}>Report</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
              {
                (roleService.isRoleStudent(props.user) && hasPermission('viewProfile', props.user.permissions))
                  ?
                  <CHeaderNavItem className="px-3">
                    <CHeaderNavLink className={isActive(profilePage())} to={profilePage}>Profile</CHeaderNavLink>
                  </CHeaderNavItem>
                  :
                  ''
              }
            </>
            : ''
        }
      </CHeaderNav>
      {
        authService.isLoggedIn() ?
          <CHeaderNav className="px-3">
            <CButton color={'light'}
                     onClick={changeStatusAction}>{!props.setting.disabledAction ? 'Cancel edit' : 'Enable edit'}</CButton>
            <TheHeaderDropdown/>
          </CHeaderNav>
          :
          null
      }
    </CHeader>
  );
};
const mapStateToProds = (state) => {
  return {
    user: state.auth.user,
    setting: state.setting
  };
};
const mapDispatchToProds = (dispatch, props) => {
  return {
    changeStatusAction: (status) => {
      dispatch(changeStatusActionRequest(status));
    }
  };
};
export default connect(mapStateToProds, mapDispatchToProds)(TheHeader);
