export const removeCertificate = (certificateId, certificates) => {
    certificates = certificates.filter(function (certificate) {
        return +certificate.id !== +certificateId;
    });
    return certificates;
};
export const updateCertificate = (certificateUpdate, certificates) => {
    let certificateIndex = certificates.findIndex(function (certificate) {
        return +certificate.id === +certificateUpdate.id;
    })
    certificates[certificateIndex] = certificateUpdate;
    return certificates;
};