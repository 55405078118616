import React from 'react'
import {
  Route,
} from 'react-router-dom'
import {TheContent, TheFooter, TheHeader} from "./../../src/containers";

const ThePublic = (...rest) => {
  return (
    <Route
      {...rest}
      render={props =>
        <div className="c-app c-default-layout">
          <div className="c-wrapper">
            <TheHeader/>
            <div className="c-body">
              <TheContent {...props} public={true}/>
            </div>
            <TheFooter/>
          </div>
        </div>
      }
    />
  )
}

export default React.memo(ThePublic)
