import * as actionCompetencesType from '../../constants/actionCompetencesType';
var initialState = {
  pending: false,
  addCompetencePending: false,
  competences: [],
  error: null
};

var competencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCompetencesType.FETCH_COMPETENCES_LOADING:
      return {
        ...state,
        competences: [],
        pending: true
      }
    case actionCompetencesType.FETCH_COMPETENCES:
      return {
        ...state,
        pending: false,
        competences: action.competences
      }
    case actionCompetencesType.FETCH_COMPETENCES_ERROR:
      return {
        ...state,
        pending: false,
        competences: [],
        error: action.error
      }
    case actionCompetencesType.ADD_COMPETENCE:
      state.competences.push(action.competence);
      return {
        ...state,
        addCompetencePending: false,
        competences: state.competences
      }
    case actionCompetencesType.ADD_COMPETENCE_LOADING:
      return {
        ...state,
        addCompetencePending: true,
        competences: state.competences
      }
    case actionCompetencesType.ADD_COMPETENCE_ERROR:
      return {
        ...state,
        addCompetencePending: false,
        competences: state.competences,
        error: action.error
      }

    case actionCompetencesType.EDIT_COMPETENCE:
      let competenceUpdateIndex = state.competences.findIndex(competence => competence.id === action.competence.id);
      state.competences[competenceUpdateIndex] = action.competence;
      return {
        ...state,
        editCompetencePending: false,
        competences: state.competences
      }
    case actionCompetencesType.EDIT_COMPETENCE_LOADING:
      return {
        ...state,
        editCompetencePending: true,
        competences: state.competences
      }
    case actionCompetencesType.EDIT_COMPETENCE_ERROR:
      return {
        ...state,
        editCompetencePending: false,
        competences: state.competences,
        error: action.error
      }
    case actionCompetencesType.REMOVE_COMPETENCE_INTO_COMPETENCES:
      state.competences = state.competences.filter(function(competence) {
        return +competence.id !== +action.competenceId
      })
    return {
      ...state,
      editCompetencePending: false,
      competences: state.competences,
      error: action.error
    }

    case actionCompetencesType.REMOVE_VERSION_COMPETENCE_INTO_COMPETENCES:
      let competenceRemoveIndex = state.competences.findIndex(competence => +competence.id === +action.competenceId);
      for( var i = 0; i < state.competences[competenceRemoveIndex].version_framework.length; i++){
        if ( +state.competences[competenceRemoveIndex].version_framework[i] === +action.version) {
          state.competences[competenceRemoveIndex].version_framework.splice(i, 1);
        }
      }

      return {
        ...state,
        editCompetencePending: false,
        competences: state.competences,
        error: action.error
      }

    case actionCompetencesType.ADD_NEW_VERSION_INTO_FRAMEWORK:
      let competenceIndex = state.competences.findIndex(competence => +competence.id === +action.competenceId);
      state.competences[competenceIndex].version_framework.push(action.version);
      return {
        ...state,
        editCompetencePending: false,
        competences: state.competences,
        error: action.error
      }
    default:
      return {...state};
  }
}
export default competencesReducer;
