import * as actionUserType from '../../constants/actionUserType';
import {setRoleUser, setRoleUserByUserId} from '../../utils/services/user';

var initialState = {
    pending: false,
    updateRoleUserPending: false,
    users: [],
    error: null
};
var usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionUserType.FETCH_USERS_IS_LOADING:
            return {
                ...state,
                users: [],
                pending: true
            };
        case actionUserType.FETCH_USERS:
            return {
                ...state,
                pending: false,
                users: action.users
            };
        case actionUserType.FETCH_USERS_IS_ERROR:
            return {
                ...state,
                pending: false,
                users: [],
                error: action.error
            };
        case actionUserType.UPDATE_ROLE_USER_IS_LOADING:
            return {
                ...state,
                updateRoleUserPending: true
            };
        case actionUserType.UPDATE_ROLE_USER:
            state.users = setRoleUserByUserId(action.userId, action.roleUser, state.users);
            return {
                ...state,
                updateRoleUserPending: false,
                users: state.users
            };
        case actionUserType.UPDATE_ROLE_USER_IS_ERROR:
            return {
                ...state,
                updateRoleUserPending: false,
                error: action.error
            };
        default:
            return {...state};
    }
};
export default usersReducer;
