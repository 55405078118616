import * as actionRoleType from '../../constants/actionRoleType';
import {setPermissionOfRole} from '../../utils/services/roleService';

var initialState = {
  pending: false,
  updateRolePermissionsPending: false,
  role: [],
  error: null
};
var roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionRoleType.FETCH_ROLE_IS_LOADING:
      return {
        ...state,
        pending: true
      };
    case actionRoleType.FETCH_ROLE:
      return {
        ...state,
        role: action.role,
        pending: false
      };
    case actionRoleType.FETCH_ROLE_IS_ERROR:
      return {
        ...state,
        role: [],
        pending: false,
        error: action.error
      };
    case actionRoleType.SET_PERMISSION_OF_ROLE:
      state.role.role.role_permission = setPermissionOfRole(action.roleId, action.permission, state.role.role.role_permission);
      return {
        ...state
      };
    case actionRoleType.UPDATE_PERMISSIONS_OF_ROLE_LOADING:
      return {
        ...state,
        updateRolePermissionsPending: true
      };
    case actionRoleType.UPDATE_PERMISSIONS_OF_ROLE:
      return {
        ...state,
        role: action.role,
        updateRolePermissionsPending: false
      };
    case actionRoleType.UPDATE_PERMISSIONS_OF_ROLE_ERROR:
      return {
        ...state,
        role: [],
        updateRolePermissionsPending: false,
        error: action.error
      };
    default:
      return {...state};
  }
};
export default roleReducer;
